import intlTelInput from 'intl-tel-input';
import { environments } from '../environments/environments';

document.addEventListener('DOMContentLoaded', () => {
  const form_modal = document.querySelector('.modal_form');
  const thanks_modal = document.querySelector('.modal_thanks');
  const form = document.getElementById('form');
  const modal_buttons = document.querySelectorAll('[data-modal]');
  const phone = document.getElementById('phone');
  const name = document.getElementById('name');
  const position = document.getElementById('position');
  const email = document.getElementById('email');
  const name_label = document.querySelector('.label_name');
  const position_label = document.querySelector('.label_position');
  const email_label = document.querySelector('.label_email');
  const modal_close = document.querySelector('.close');
  const thanks_close = document.querySelector('.thanks_close');
  const submit = document.querySelector('.submit');
  const nav_links = document.querySelectorAll('.nav a');
  const burger = document.querySelector('.header_burger');
  const header_nav = document.querySelector('.header_wrap');
  const isMobile = window.innerWidth <= 768;
  const mobileBrowser = /Android.+Mobile|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  let iti;

  if (mobileBrowser) {
    document.body.classList.add('iti-mobile');
  }

  burger.addEventListener('click', function() {
    this.classList.toggle('open');
    header_nav.classList.toggle('visible');
  });

  form.addEventListener('change', () => {
    submit.disabled = !form.checkValidity();
  });

  form.addEventListener('submit', function(e) {
    e.preventDefault();

    const fields = {
      name: name.value.trim(),
      phone: `+${iti.getSelectedCountryData().dialCode}${phone.value}`,
      email: email.value.trim(),
      position: position.value.trim(),
    };

    send(fields).then();
  });

  modal_buttons.forEach(item => {
    item.addEventListener('click', () => {
      toggleModal(form_modal);
      closeMobileNav();
    });
  });

  nav_links.forEach(el => {
    el.addEventListener('click', function(e) {
      e.preventDefault();
      const item = document.querySelector(this.getAttribute('href'));
      scrollTo(item, isMobile ? 60 : 100);
      closeMobileNav();
    });
  });

  modal_close.addEventListener('click', () => toggleModal(form_modal));
  thanks_close.addEventListener('click', () => toggleModal(thanks_modal));

  setInputFilter(phone, (value) => /^-?\d*$/.test(value));
  toggleLabel(name, name_label);
  toggleLabel(position, position_label);
  toggleLabel(email, email_label);

  getCountry().then();
  //get country code by ip
  async function getCountry() {
    await fetch(environments.IP_SERVICE)
      .then(res => res.json())
      .then(res => {
        iti = intlTelInput(phone, {
          separateDialCode: true,
          initialCountry: res ? res.country_code.toLowerCase() : 'ua',
          preferredCountries: [],
        });
      })
      .catch(() => {
        iti = intlTelInput(phone, {
          separateDialCode: true,
          initialCountry: 'ua',
          preferredCountries: [],
        });
      })
  }

  function toggleModal(node) {
    node.classList.toggle('modal_show');

    if (node.classList.contains('modal_form')) {
      name.required = !!form_modal.classList.contains('modal_show');
      email.required = !!form_modal.classList.contains('modal_show');

      if (!form_modal.classList.contains('modal_show')) {
        form.reset();
        submit.disabled = true;
      }
    }
  }

  async function send(form) {
    submit.disabled = true;

    const formFields = {
      ...form,
      token: environments.PROD_TOKEN,
    };

    const formData = new FormData();

    Object.keys(formFields).forEach(item => {
      formData.append(item, formFields[item]);
    });

    await fetch(environments.API_PROD, {
      method: 'POST',
      body: formData,
    })
      .then(() => {
        toggleModal(form_modal);
        toggleModal(thanks_modal);
      });
  }

  //only numbers in phone field
  function setInputFilter(textbox, inputFilter) {
    ['input', 'keydown', 'keyup', 'mousedown', 'mouseup', 'select', 'contextmenu', 'drop'].forEach(function(event) {
      textbox.addEventListener(event, function() {
        if (inputFilter(this.value)) {
          this.oldValue = this.value;
          this.oldSelectionStart = this.selectionStart;
          this.oldSelectionEnd = this.selectionEnd;
        } else if (this.hasOwnProperty('oldValue')) {
          this.value = this.oldValue;
          this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
        } else {
          this.value = '';
        }
      });
    });
  }

  function scrollTo(element, decreaseTop) {
    window.scroll({
      behavior: 'smooth',
      left: 0,
      top: (element.offsetTop - decreaseTop),
    });
  }

  function closeMobileNav() {
    if (!burger.classList.contains('open')) {
      return;
    }

    burger.classList.toggle('open');
    header_nav.classList.toggle('visible');
  }

  function toggleLabel(node, label) {
    node.addEventListener('focus', () => label.classList.add('visible'));
    node.addEventListener('focusout', function() {
      if (!this.value.length) {
        label.classList.remove('visible');
      }
    });
  }
});